












































































































































































































































































import Ueditor from "@/components/Ueditor/index.vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  DataDictionaryDtoPagedResultDto,
  FundDtoPagedResultDto,
  FundIncomeRecordCreateOrUpdateDto,
  FundProjectDto,
  FundProjectDtoPagedResultDto,
  ElectronicInvoiceType,
  FundProjectType,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import validate from "@/utils/validate";

@Component({
  components: {
    AbSelect,
    Ueditor,
    MultipleUploadFile,
    SimpleUploadImage,
  },
})
export default class CreateFundIncomeRecord extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: any = {
    id: 0,
    fundProjectID: undefined,
    fundID: undefined,
    invoiceInformation: {
      title: undefined,
      name: undefined,
      telephone: undefined,
      address: undefined,
    },
    type: "Personal",
    invoiceTitle: undefined,
    invoiceTelephone: undefined,
    creditCode: undefined,
    idNo: undefined,
    email: undefined,
  };
  projectList: any[] = [];
  fundList: any[] = [];

  donorType: any = [];
  incomeType: any = [];
  paymentType: any = [];
  currencyType: any = [];
  isFundProject = true;
  loading = true;
  btnDisabled = false;
  typeList = [
    {
      key: "个人",
      value: ElectronicInvoiceType.Personal,
    },
    {
      key: "企业或其他组织",
      value: ElectronicInvoiceType.EnOrOtherOrg,
    },
  ];

  async created() {
    await this.fetchDataDictionary();
    await this.fetchEnumType();
    await this.fetchFundProjectList();
    await this.fetchFundList();

    if (this.$route.params.id) {
      await api.fundIncomeRecord
        .get({ id: Number(this.$route.params.id) })
        .then((res) => {
          this.form = { ...res };
          this.form.invoiceTitle = this.form?.invoiceInformation!.title;
          this.form.invoiceName = this.form?.invoiceInformation!.name;
          this.form.invoiceTelephone = this.form?.invoiceInformation!.telephone;
          this.form.invoiceAddress = this.form?.invoiceInformation!.address;
          this.form.type = this.form?.invoiceInformation!.type;
          this.form.creditCode = this.form?.invoiceInformation!.creditCode;
          this.form.idNo = this.form?.invoiceInformation!.idNo;
          this.form.email = this.form?.invoiceInformation!.email;
        });
    }

    this.selectIncomeType(this.form.incomeType);
    this.loading = false;
  }

  async fetchFundProjectList() {
    await api.fundProject
      .getAll({
        projectType: FundProjectType.OnlineFundraising,
        maxResultCount: 10000,
      })
      .then((res: FundProjectDtoPagedResultDto) => {
        this.projectList = res.items ?? [];
      });
  }

  async fetchFundList() {
    await api.fund
      .getAll({
        maxResultCount: 10000,
      })
      .then((res: FundDtoPagedResultDto) => {
        this.fundList = res.items ?? [];
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "PaymentType", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.paymentType = res.items;
      });
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "DonorType",
      })
      .then((res) => {
        this.donorType = res;
      });
    await api.enumService
      .getValues({
        typeName: "IncomeType",
      })
      .then((res) => {
        res.forEach((item) => {
          // if (item.value == 'Project' || item.value == 'Fund') {
          this.incomeType.push(item);
          // }
        });
      });
    await api.enumService
      .getValues({
        typeName: "CurrencyType",
      })
      .then((res) => {
        this.currencyType = res;
      });
  }

  selectIncomeType(e: any) {
    // this.form.fundID = undefined
    // this.form.fundProjectID = undefined

    if (e == "Project") {
      this.form.fundID = undefined;
      // this.fetchFundProjectList()
      this.isFundProject = true;
    } else if (e == "Fund") {
      this.form.fundProjectID = undefined;
      // this.fetchFundList()
      this.isFundProject = false;
    } else {
      this.form.fundID = undefined;
      this.form.fundProjectID = undefined;
    }
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.fundIncomeRecord
            .update({
              body: this.form,
            })
            .then(() => {
              this.$router.back();
              this.$message.success("新增成功");
            })
            .catch(() => {
              this.btnDisabled = false;
            });
        } else {
          await api.fundIncomeRecord
            .create({
              body: this.form,
            })
            .then(() => {
              this.$router.back();
              this.$message.success("修改成功");
            })
            .catch(() => {
              this.btnDisabled = false;
            });
        }
      } else {
        this.$message.error("请检查表单！");
        this.btnDisabled = false;
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  roleRule = {
    paymentType: [
      {
        required: true,
        message: "支付类型必填",
        trigger: "blur",
      },
    ],
    donorType: [
      {
        required: true,
        message: "捐赠者类型必填",
        trigger: "blur",
      },
    ],
    incomeType: [
      {
        required: true,
        message: "捐赠意向必填",
        trigger: "blur",
      },
    ],
    fundProjectID: [
      {
        required: true,
        message: "项目必填",
        trigger: "blur",
      },
    ],
    fundID: [
      {
        required: true,
        message: "基金必填",
        trigger: "blur",
      },
    ],
    moneyAmount: [
      {
        required: true,
        message: "捐赠金额必填",
        trigger: "blur",
      },
      {
        pattern: /^[0-9|^\\.]/,
        message: "捐赠金额不能为负数",
      },
    ],
    incomeTime: [
      {
        required: true,
        message: "捐赠时间必填",
        trigger: "blur",
      },
    ],
    donorEmail: [
      {
        message: "请填写正确的邮箱地址",
        type: "email",
        trigger: "blur",
      },
    ],
    currencyType: [
      {
        required: true,
        message: "请选择币种",
        trigger: "blur",
      },
    ],
    donorPostCode: [
      {
        pattern: /^\d{6}$/,
        message: "请输入正确的邮政编码",
        trigger: "blur",
      },
    ],
    invoiceTelephone: [
      {
        required: true,
        trigger: ["blur", "change"],
        validator: validate.validPhone,
      },
    ],
  };
}
